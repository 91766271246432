import { createStore } from 'redux'
import { useSelector, TypedUseSelectorHook } from 'react-redux'

export type ReduxState = {
  sidebarShow: 'responsive' | boolean,
  isSideBarVisible: boolean,
  asideShow: boolean,
  darkMode: boolean,
  shouldRefreshScreen: boolean
  permissions: any,
  isLogged: boolean,
  isSubscribed: boolean,
  navItems: any[],
  selectedOrganization: any,
  installEvent:any,
  farmState: any,
  pageHeader: string;
  fieldName: string | null, 
}

const initialState: ReduxState = {
  sidebarShow: 'responsive',
  isSideBarVisible: true,
  asideShow: false,
  darkMode: false,
  shouldRefreshScreen: false,
  permissions: undefined,
  isLogged: localStorage.getItem('isLoggedIn') === 'true' ? true : false,
  navItems: [],
  isSubscribed: true,
  selectedOrganization: JSON.parse(localStorage.getItem('orgState')!) || {},
  installEvent: {},
  farmState: JSON.parse(localStorage.getItem('farmState')!),

  pageHeader: '',
  fieldName: null
}
type args = { type?: string, [key: string]: any }

const changeState = (state = initialState, { type, ...rest }: args) => {

  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'setShouldRefreshScreen':
      return { ...state, shouldRefreshScreen: !state.shouldRefreshScreen }
    case 'setFieldName': // Add this case to handle field name
      return { ...state, fieldName: rest.fieldName }
    default:
      return state
  }
}


// Action to set the field name
export const setFieldName = (fieldName: string | null) => ({
  type: 'setFieldName',
  fieldName,
})
const store = createStore(changeState)
export default store

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector
