import { useState } from "react";
import { IOption } from "../index.interface";

const useSearchDropDownListUpdated = (
  onChangeHandler: Function | undefined,
  className: string = "",
  uniqueId: string,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ref: React.RefObject<HTMLDivElement>,
) => {
  const [selectedOption, setSelectedOption] = useState<any>({});

  const handleChange = (options: IOption[]) => {
    if (options?.length > 0) {
      if (onChangeHandler) onChangeHandler(options);
      setSelectedOption(options);
      toggleDropDownShow();
    }
  };

  const toggleDropDownShow = () => {
    const dropdown = document.querySelector(
      `${
        className ? `.${className}` : ""
      } .search-drop-down-list[data-id="${uniqueId}"] .dropdown.picker.form-multi-select.form-multi-select-with-cleaner.search-drop-down-list .dropdown-menu`,
    );
    if (dropdown?.classList.contains("show")) {
      dropdown?.classList.remove("show");
    } else {
      dropdown?.classList.add("show");
    }
  };

  const handleDropDownLossFocus = (event: MouseEvent): void => {
    const target = event.target;
    // console.log(target);

    if (
      ref.current &&
      target instanceof HTMLElement &&
      target.getAttribute("data-id") == uniqueId &&
      target.className
    ) {
      setOpen(true);
      toggleDropDownShow();
    } else if (
      ref.current &&
      target instanceof HTMLElement &&
      target.className !== "form-multi-select-search"
    ) {
      setOpen(false);
    }
  };

  return {
    selectedOption,
    handleChange,
    toggleDropDownShow,
    handleDropDownLossFocus,
  };
};

export default useSearchDropDownListUpdated;
