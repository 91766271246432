import { FC, useEffect, useRef, useState } from "react";
import { CMultiSelect } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import useSearchDropDownListUpdated from "./hooks/useSearchDrownListUpdated";
import SearchDropDownListUpdatedProps from "./index.interface";
import DropdownWithPortal from "./partials/DropdownWithPortal";

const SearchDropDownListUpdated: FC<SearchDropDownListUpdatedProps> = ({
  options,
  selectedValue,
  onChangeHandler,
  valueWidth,
  valueHeight,
  valueIcon,
  className = "",
  error,
  uniqueId, // New prop for unique identifier
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const {
    selectedOption,
    handleChange,
    toggleDropDownShow,
    handleDropDownLossFocus,
  } = useSearchDropDownListUpdated(
    onChangeHandler,
    className,
    uniqueId,
    setOpen,
    divRef,
  );
  const valueStyle = { width: valueWidth, height: valueHeight };

  interface HandleClickEvent extends MouseEvent {
    target: EventTarget & HTMLElement;
  }

  useEffect(() => {
    document.addEventListener("click", handleDropDownLossFocus);

    return () => {
      document.removeEventListener("click", handleDropDownLossFocus);
    };
  }, []);

  return (
    <div
      className={`search-drop-down-list ${className}`}
      data-id={uniqueId} // Add uniqueId as a data-id attribute
      onClick={toggleDropDownShow}
    >
      <div
        className="value-label "
        style={valueStyle}
        onClick={toggleDropDownShow}
        data-id={uniqueId}
        ref={divRef}
      >
        {valueIcon ? valueIcon : ""}
        {selectedOption[0]
          ? selectedOption[0].text
          : selectedValue
          ? selectedValue
          : ""}
      </div>
      {error && <div className="error mt-2 regular-font">{error}</div>}
      {open && (
        <CMultiSelect
          options={options}
          virtualScroller
          className="search-drop-down-list"
          selectAll={false}
          multiple={false}
          onChange={handleChange}
          searchNoResultsLabel={t("noItemsFound")}
          placeholder={t("search")}
        />
      )}
    </div>
  );
};

export default SearchDropDownListUpdated;
