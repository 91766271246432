import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { nameRegex } from "utils/regex";
import { purifyText } from "utils/textUtil";

const useAddNewFarmModal = (t: Function, onSend: Function) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [farmName, setFarmName] = useState("");
  const [validFarmName, setValidFarmName] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  // const navigate = useNavigate();

  document.onkeydown = (e: any) => enter(e);
  function enter(e: any) {
    if (
      (e.key === "Enter" || e.keyCode === 13) &&
      validFarmName &&
      !keyPressed
    ) {
      handleProceed();
      setKeyPressed(true);
    }
  }
  const onCloseModalHandler = () => {
    setShowModal(false);
    setValidFarmName(undefined);
    setFarmName("");
  };
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const specials = /^\s|[^a-zA-Z0-9 \u0600-\u06FF]/;
    if (specials.test(e.target.value)) {
      setValidFarmName(false);
      setErrorMessage(t("farmSpecialCharactersNotAllowed"));
      return;
    }
    let currentName = purifyText(e.target.value);
    setFarmName(currentName);
    if (
      currentName.length >= 5 &&
      currentName.length <= 40 &&
      !nameRegex.test(currentName)
    ) {
      setValidFarmName(true);
      setErrorMessage("");
    } else {
      if (nameRegex.test(currentName)) {
        setErrorMessage(t("farmSpecialCharactersNotAllowed"));
      } else {
        setErrorMessage(t("farmNameRangeError"));
      }
      setValidFarmName(false);
    }
  };
  const handleProceed = () => {
    if (validFarmName) {
      sendData();
      onCloseModalHandler();
    }
  };
  const sendData = () => {
    onSend(farmName);
  };
  return {
    handleProceed,
    showModal,
    setShowModal,
    farmName,
    setFarmName,
    onCloseModalHandler,
    validFarmName,
    setValidFarmName,
    errorMessage,
    setErrorMessage,
    onChangeHandler,
  };
};

export default useAddNewFarmModal;
