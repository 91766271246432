import { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import newFarm from "assets/icons/svg/AssociatedFarms/NFState.svg";
import Input from "components/Input";
import ProceedButton from "components/ProceedButton";
import mapInactive from "assets/icons/svg/sidebar/inactive/map.svg";
import { NewFarmModalProps } from "./index.interface";

const NewFarmModal: FC<NewFarmModalProps> = ({
  onCloseModal,
  handleProceed,
  showModal,
  farmName,
  onCloseModalHandler,
  validFarmName,
  errorMessage,
  onChangeHandler,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showModal} closeModal={onCloseModalHandler}>
      <div className={"Add-New-Farm-Modal__wrapper"}>
        <div className={"Add-New-Farm-Modal__wrapper__image"}>
          <img src={newFarm} />
        </div>
        <p className={"Add-New-Farm-Modal__wrapper__header"}>
          {t("addNewFarm")}
        </p>
        <p className={"Add-New-Farm-Modal__wrapper__subheader"}>
          {t("newFarmsubHeader")}
        </p>
        <div className={"Add-New-Farm-Modal__wrapper__input"}>
          <Input
            inputType={"text"}
            title={t("farmName")}
            handleChange={onChangeHandler}
            defaultValue={farmName}
            errorMessage={errorMessage}
            iconImage={<img src={mapInactive} />}
            textLength={"medium"}
          />
          {validFarmName === false && (
            <span
              className={
                errorMessage
                  ? "farm-name-validation failure"
                  : "farm-name-validation"
              }
            >
              {errorMessage as String}
            </span>
          )}
        </div>
        {/* {validFarmName === false && errorMessage ? <Alert errorMessage={errorMessage as String} /> : null} */}
        <ProceedButton
          disabled={!validFarmName}
          handleClick={handleProceed}
          title={t("setFarmName")}
        />
      </div>
    </Modal>
  );
};

export default NewFarmModal;
