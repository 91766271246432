import { get, post } from 'services/ApiFetching';
export const getCrops = async () => {
  const response = await get(`/crops/list`);
  return response;
};

export const getIrrigationTypes = async () => {
  const response = await get(`/farms/irrigation-types`);
  return response;
};

export const getSoilTypes = async () => {
  const response = await get(`/farms/soil-types`);
  return response;
};

export const defineFarm = async (farmObject: any) => {
  const response = await post(`/farms/add`, farmObject, "json", 60000);
  return response;
};
