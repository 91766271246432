import { FC, useEffect, useState } from "react";
import MapView from "./partials/MapView";
import PanelView from "./partials/PanelView";
import useMap from "./partials/MapView/hooks/useMap";
import { useTranslation } from "react-i18next";
import usePanel from "./partials/PanelView/hooks/usePanel";
import Modal from "components/Modal";
import ProceedButton from "components/ProceedButton";
import { useNavigate } from "react-router";
import warningIcon from "assets/icons/svg/calendar/warningIcon.svg";
import useAddNewFarmModal from "components/AddNewFarmModal/hooks/useAddNewFarmModal";
import NewFarmModal from "components/AddNewFarmModal";
import { useLocation } from "react-router-dom";
import useOrganizationInfo from "./hooks/useOrganizationInfo";
import ConfirmationMessage from "components/ConfirmationMessage";
import Loader from "components/Loading";

const FarmRegistration: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  if (!state?.name) {
    navigate("/UserFarms");
  }
  const { organizationDetails, selectedOrganization } = useOrganizationInfo();
  const {
    currentFarmNameEn,
    currentFarmNameAr,
    handleFarmDescriptionChange,
    handlePlotTypeSelectionChange,
    handleSoilTypeSelectionChange,
    handleFarmLocationChange,
    farmDescription,
    handleSubmitPlot,
    addNewPlot,
    plotsList,
    handlePlotNameChange,
    showMoreLanguages,
    handleShowMoreLanguagesToggle,
    showMoreLanguagesFields,
    handlePlotShowMoreLanguagesToggle,
    cropsList,
    irrigationList,
    soilList,
    handleSelectedCrop,
    handleDefineFarm,
    disableDefineFarm,
    enableDrawing,
    setEnableDrawing,
    sendToModalFunction,
    handlePlotDelete,
    selectedSoilType,
    handleSelectedSoil,
    handlePlotEdit,
    updatePlot,
    responseMessage,
    setResponeMessage,
    handleFarmNameChange,
    errorFields,
    farmLocationErrorField,
    farmDescriptionErrorField,
    farmLocationSecondaryErrorField,
    userSubmitted,
    farmNameSecondaryErrorField,
    plotNameErrors,
  } = usePanel(t, selectedOrganization);
  const {
    handleProceed,
    showModal,
    farmName,
    setShowModal,
    onCloseModalHandler,
    validFarmName,
    errorMessage,
    onChangeHandler,
  } = useAddNewFarmModal(t, sendToModalFunction);

  const locale = localStorage.getItem("i18nextLng");

  const choicesList = [
    <div id="1" placeholder={t("farmHigherResolution")}>
      {t("farmHigherResolution")}
      <div className="choice-content-wrapper">{t("farmResolutionContent")}</div>
    </div>,
    <div id="2" placeholder={t("farmLatestImage")}>
      {t("farmLatestImage")}
      <div className="choice-content-wrapper">{t("farmLatestContent")}</div>
    </div>,
  ];

  const {
    drawingMethod,
    setDrawingMethod,
    acresUsed,
    mapRef,
    currentMapMode,
    setCurrentMapMode,
    firstSearchTime,
    layerLoading,
    setFirstSearchTime,
    isDropdownFocused,
    setIsDropdownFocused,
    selectedSearchMethod,
    setSelectedSearchMethod,
    handleWGSCoordinates,
    handleCoordinates,
    handleSelectedSearchMethod,
    wgsCoordinates,
    coordinates,
  } = useMap(
    t,
    plotsList,
    organizationDetails,
    addNewPlot,
    enableDrawing,
    setEnableDrawing,
    updatePlot,
  );
  const [showCancelModal, setShowCancelModal] = useState<boolean>();

  if (!cropsList || !organizationDetails || !irrigationList || !soilList)
    return <Loader />;

  return (
    <div className="farm-registration-wrapper">
      <MapView
        t={t}
        mapRef={mapRef}
        currentMapMode={currentMapMode}
        setCurrentMapMode={setCurrentMapMode}
        acresUsed={acresUsed}
        enableDrawing={enableDrawing}
        organizationDetails={organizationDetails}
        drawingMethod={drawingMethod}
        setDrawingMethod={setDrawingMethod}
        choicesList={choicesList}
        firstSearchTime={firstSearchTime}
        layerLoading={layerLoading}
        setFirstSearchTime={setFirstSearchTime}
        isDropdownFocused={isDropdownFocused}
        setIsDropdownFocused={setIsDropdownFocused}
        handleWGSCoordinates={handleWGSCoordinates}
        handleCoordinates={handleCoordinates}
        selectedSearchMethod={selectedSearchMethod}
        handleSelectedSearchMethod={handleSelectedSearchMethod}
        wgsCoordinates={wgsCoordinates}
        coordinates={coordinates}
      />
      <PanelView
        t={t}
        handlePlotEdit={handlePlotEdit}
        showMoreLanguages={showMoreLanguages}
        handleSubmitPlot={handleSubmitPlot}
        selectedSoilType={selectedSoilType}
        handleSelectedSoil={handleSelectedSoil}
        handleShowMoreLanguagesToggle={handleShowMoreLanguagesToggle}
        setShowModal={setShowModal}
        handlePlotShowMoreLanguagesToggle={handlePlotShowMoreLanguagesToggle}
        handlePlotTypeSelectionChange={handlePlotTypeSelectionChange}
        handleSoilTypeSelectionChange={handleSoilTypeSelectionChange}
        disableDefineFarm={disableDefineFarm}
        handlePlotNameChange={handlePlotNameChange}
        plotsList={plotsList}
        enableDrawing={enableDrawing}
        handleFarmNameChange={handleFarmNameChange}
        setShowCancelModal={setShowCancelModal}
        farmDescription={farmDescription}
        showMoreLanguagesFields={showMoreLanguagesFields}
        farmNameEn={currentFarmNameEn}
        farmNameAr={currentFarmNameAr}
        handleFarmDescriptionChange={handleFarmDescriptionChange}
        handleFarmLocationChange={handleFarmLocationChange}
        handleDefineFarm={handleDefineFarm}
        cropsList={cropsList}
        irrigationList={irrigationList}
        soilList={soilList}
        handleSelectedCrop={handleSelectedCrop}
        handlePlotDelete={handlePlotDelete}
        errorFields={errorFields}
        farmLocationErrorField={farmLocationErrorField}
        farmDescriptionErrorField={farmDescriptionErrorField}
        farmLocationSecondaryErrorField={farmLocationSecondaryErrorField}
        userSubmitted={userSubmitted}
        farmNameSecondaryErrorField={farmNameSecondaryErrorField}
        plotNameErrors={plotNameErrors}
      />
      <Modal
        show={showCancelModal}
        closeModal={() => {
          setShowCancelModal(false);
        }}
      >
        <div className={"modal-body-container"}>
          <img className="cancel-icon" src={warningIcon} />
          <p className={"cancel-header"}>{t("reportingProcessCancellation")}</p>
          <p className={"cancel-subheader"}>{t("cancelFarmSubheader")}</p>
          <ProceedButton
            disabled={false}
            handleClick={() => {
              setShowCancelModal(false);
              navigate("/UserFarms");
            }}
            title={t("cancelDefinition")}
          />
        </div>
      </Modal>
      {showModal && (
        <NewFarmModal
          onCloseModal={onCloseModalHandler}
          handleProceed={handleProceed}
          showModal={showModal}
          farmName={locale === "en" ? currentFarmNameEn : currentFarmNameAr}
          setShowModal={setShowModal}
          onCloseModalHandler={onCloseModalHandler}
          validFarmName={validFarmName}
          errorMessage={errorMessage}
          onChangeHandler={onChangeHandler}
        />
      )}
      {responseMessage && (
        <ConfirmationMessage
          type={responseMessage.type}
          handleButtonClick={() => setResponeMessage(undefined)}
          messageTitle={responseMessage.messageTitle}
          messageSubtitle={responseMessage.messageSubtitle}
        />
      )}
    </div>
  );
};

export default FarmRegistration;
